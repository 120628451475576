<template>
  <div>
    <div>
      <v-expansion-panels class="ml-4" v-model="panel">
        <v-col cols="3" class="d-flex child-flex justify-space-between align-center">
          <v-img
            :src="editedDelinquent.picture"
            alt="editedDelinquent.firstName"
            class="grey fill-height lighten-2"
            contain
          />
        </v-col>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Delinquent Details</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="12">
                <p class="mb-n4 font-weight-thin">Personal Identification</p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="NID"
                  type="text"
                  outlined
                  v-model="editedDelinquent.nid"
                  filled
                  color="grey"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Passport"
                  type="text"
                  outlined
                  v-model="editedDelinquent.passport"
                  color="grey"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Phone"
                  type="text"
                  outlined
                  v-model="editedDelinquent.guardianPhone"
                  color="grey"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="First Name"
                  type="text"
                  outlined
                  v-model="editedDelinquent.firstName"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Last Name"
                  type="text"
                  outlined
                  v-model="editedDelinquent.lastName"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Gender"
                  item-text="name"
                  outlined
                  v-model="editedDelinquent.gender"
                  return-object
                  class="mt-n6"
                  color="grey"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Marital Status"
                  item-text="name"
                  outlined
                  v-model="editedDelinquent.maritalStatus"
                  class="mt-n6"
                  color="grey"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Father Name"
                  v-model="editedDelinquent.fatherName"
                  type="text"
                  outlined
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Mother Name"
                  type="text"
                  outlined
                  v-model="editedDelinquent.motherName"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="12">
                <p class="mt-n6 font-weight-thin">Place and Date of Birth</p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthProvinces"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.province.id"
                  dense
                  color="grey"
                  label="Province"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthDistricts"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.district.id"
                  dense
                  color="grey"
                  label="District"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthSectors"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.sector.id"
                  dense
                  color="grey"
                  label="Sector"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthCells"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.cell.id"
                  dense
                  color="grey"
                  label="Cell"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthVillages"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.village.id"
                  dense
                  color="grey"
                  label="Village"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedDelinquent.latestScreening.meetingDate"
                  label="Meeting Date"
                  outlined
                  filled
                  color="grey"
                  class="mt-n6"
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <p class="mt-n6 font-weight-thin">Place of Residence</p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceProvinces"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.province
                      .id
                  "
                  dense
                  color="grey"
                  label="Province"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceDistricts"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.district
                      .id
                  "
                  dense
                  color="grey"
                  label="District"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceSectors"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.sector.id
                  "
                  dense
                  color="grey"
                  label="Sector"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceCells"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.cell.id
                  "
                  dense
                  color="grey"
                  label="Cell"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceVillages"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.village.id
                  "
                  dense
                  color="grey"
                  label="Village"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Next Of Kin Name"
                  type="text"
                  v-model="editedDelinquent.guardian"
                  outlined
                  name="Next Of Kin Name"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Next Of Kin Phone"
                  type="text"
                  outlined
                  v-model="editedDelinquent.guardianPhone"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Next Of Kin Email"
                  type="text"
                  outlined
                  v-model="editedDelinquent.guardianEmail"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Apprehension Details</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="12">
                <p class="font-weight-thin">
                  Place, Date and other details of Apprehension
                </p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionProvinces"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.province.id
                  "
                  dense
                  color="grey"
                  label="Province"
                  outlined
                  name="Apprehension Province"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionDistricts"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.district.id
                  "
                  dense
                  color="grey"
                  label="District"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionSectors"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.sector.id
                  "
                  dense
                  color="grey"
                  label="Sector"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionCells"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.cell.id
                  "
                  dense
                  color="grey"
                  label="Cell"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionVillages"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.village.id
                  "
                  dense
                  color="grey"
                  label="Village"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedDelinquent.latestScreening.meetingDate"
                  label="Meeting Date"
                  outlined
                  filled
                  color="grey"
                  class="mt-n6"
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  dense
                  label="How he/she was apprehended and act he/she was doing"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.apprehensionWay"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Deviant Acts"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.deviantActs"
                  name="Last Name"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Apprehension Items"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.items"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Identification Documents"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.documentTypes"
                  name="Last Name"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Screening Details</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Mother Vital Status"
                  outlined
                  v-model="editedDelinquent.motherVitalStatus"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Father Vital Status"
                  outlined
                  v-model="editedDelinquent.fatherVitalStatus"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Homeless Status"
                  v-model="editedDelinquent.homeless"
                  outlined
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Specific Disease"
                  type="text"
                  outlined
                  v-model="editedDelinquent.specificDisease"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Education"
                  outlined
                  v-model="editedDelinquent.educationLevel"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedDelinquent.latestScreening.meetingDate"
                  label="Meeting Date"
                  outlined
                  filled
                  color="grey"
                  class="mt-n6"
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Decision taken"
                  outlined
                  v-model="editedDelinquent.latestScreening.decision"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Reason for decision taken"
                  type="text"
                  outlined
                  v-model="editedDelinquent.latestScreening.decisionReason"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Apprehendee opinion"
                  type="text"
                  outlined
                  v-model="
                    editedDelinquent.latestScreening.delinquentPointOfView
                  "
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Parent/Next Of Kin opinion"
                  type="text"
                  outlined
                  v-model="editedDelinquent.latestScreening.guardianPointOfView"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// import { WebCam } from 'vue-web-cam'
import { mapState, mapMutations } from 'vuex'
import Delinquent from '@/models/Delinquent'
export default {
  data () {
    return {
      birthProvinces: [],
      apprehensionProvinces: [],
      residenceProvinces: [],
      birthDistricts: [],
      apprehensionDistricts: [],
      residenceDistricts: [],
      birthSectors: [],
      apprehensionSectors: [],
      residenceSectors: [],
      birthCells: [],
      apprehensionCells: [],
      residenceCells: [],
      birthVillages: [],
      apprehensionVillages: [],
      residenceVillages: [],
      editedDelinquent: new Delinquent(),
      panel: 3
    }
  },
  computed: {
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'delinquentItem',
      'storeProvincesError',
      'storeProvinces'
    ])
  },
  created () {
    this.loadBirthProvinces()
    this.switchPanel()
  },
  watch: {
    editedDelinquent (val) {
      this.panel = 0
    }
  },
  methods: {
    // ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM'
    }),
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    switchPanel () {
      this.$delinquentService
        .loadDelinquentIdentification(
          this.delinquentItem.delinquent.id,
          'latest_apprehension,general_screening,medical_screening',
          'REHABILITATION'
        )
        .then((response) => {
          if (!response.status) {
            this.editedDelinquent = response
            // this.transferId = response.latestTransfer.id
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            // this.loadAssessments()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    fetchBirthSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.placeOfBirth)
        .then((response) => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.birthDistricts,
              this.birthSectors,
              this.birthCells,
              this.birthVillages
            ] = response
          }
        })
    },
    fetchApprehensionSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace)
        .then((response) => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.apprehensionDistricts,
              this.apprehensionSectors,
              this.apprehensionCells,
              this.apprehensionVillages
            ] = response
          }
        })
    },
    fetchResidenceSiblings () {
      this.$locationService
        .fetchAllSiblings(
          this.editedDelinquent.apprehension.delinquentResidence
        )
        .then((response) => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.residenceDistricts,
              this.residenceSectors,
              this.residenceCells,
              this.residenceVillages
            ] = response
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
